/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

#vertical-align {
  min-height: 100%;
  background-image: url("./assets/fundo-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;

  .text-footer {
    text-align: center;
    position: absolute;
    bottom: 10px;
  }
}

.logo {
  width: 70%;
  margin-top: 12px;
}

.profile-form {
  width: 100%;
  margin: 0 auto;
}

.assessment-list {
  width: 100%;
  margin: 0 auto;
}

ion-button {
  text-transform: none;
}

ion-list {
  background: transparent !important;
}

ion-alert,
ion-picker,
ion-modal,
ion-loading,
ion-menu {
  backdrop-filter: blur(7px);
}

#list-empty {
  display: flex;
  align-items: center;

  & > div {
    width: 100%;

    h4 {
      color: var(--ion-color-step-500);
    }

    ion-icon {
      font-size: 5rem;
    }
  }
}

ion-item {
  --background: none;
  --padding-start: .5rem;
  --inner-padding-end: .5rem;
}

ion-button {
  height: auto;
  --border-radius: 1rem;
  --padding-top: 1.1rem;
  --padding-bottom: 1.1rem;
}

ion-alert {
  .alert-radio-group .alert-radio-label {
    white-space: pre-wrap;
  }
}

.ql-editor {
  padding: 0;

  .ql-video {
    width: 100%;
    min-height: 12rem;
  }
}

ion-content {
  --padding-top: 0 !important;
  --padding-left: 0 !important;
  --padding-right: 0 !important;
  --padding-bottom: 0 !important;
}

ion-item {
  border-radius: 15px !important;
  padding: 5px !important;
  margin: 0 0 10px 0;
}

.bgColor {
  padding: 20px 10px;
}

@media (min-width: 1024px) {
  .logo {
    width: 20%;
  }

  .logo-auth {
    width: 40%;
  }

  .profile-form {
    width: 40%;
  }

  .assessment-list {
    width: 40%;
  }
}

@media (max-width: 991px) {
  #list-empty {
    min-height: 100%;
  }
}
